<template>
<el-container v-if="form.patrol_type==3" class="patroladd">
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
       <el-breadcrumb-item>巡查列表</el-breadcrumb-item>
       <el-breadcrumb-item>工单派发</el-breadcrumb-item>
    </el-breadcrumb>
        <div>
             <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
            <el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="p15 pt0">
        <el-form :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
     <div class="box mt20 f14">
        <div class="tl c333" >工单号：{{form.work_order}}<span class="ml10 f13 patrol_way">工单派发</span></div>
     </div>
     <el-row class="box mt20  f14">
         <el-col :span="11" class="tl" >
        <el-form-item class="w" label-width="28%" label="派发人：" size="medium" >
           <el-input placeholder="派发人" disabled v-model="user_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
          <el-form-item class="w" label-width="28%" label="巡查时间：" size="medium" prop="patrol_time" :rules="[{required: true,message: '请选择日期', trigger: 'blur' }]">
           <el-date-picker type="date" placeholder="选择日期" :picker-options="pickerOptionsStart" v-model="form.patrol_time"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;" @change="change(3,form.patrol_time)"></el-date-picker>
        </el-form-item>
        </el-col>
        <el-col :span="11 "  class="tl">
          <el-form-item class="w " label-width="28%"   label="巡查人：" size="medium" prop="patrol_user_name" :rules="[{required: true,message: '请选巡查人', trigger: ['blur','change']}]">
              <el-select v-if="this.$route.query.val"  v-model="form.patrol_user_name" filterable placeholder="请选择巡查人" disabled>
            <el-option  v-for="item in user_list" :key="item.user_name" :label="item.user_name" :value="item.user_name" ></el-option>
          </el-select>
          <el-select v-else  v-model="form.patrol_user_name" filterable placeholder="请选择巡查人"  clearable @change="change(5,form.patrol_user_name)">
            <el-option  v-for="item in user_list" :key="item.user_name" :label="item.user_name" :value="item.user_name" ></el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
        <el-form-item  class="w" label-width="28%"  label="巡查对象：" size="medium" prop="company_name" :rules="[{required: true,message: '请选择巡查对象', trigger: 'blur' }]">
           <el-select v-if="this.id2==1"  v-model="form.company_name" filterable placeholder="请选择巡查对象"  clearable @change="change(4,form.company_name)">
            <el-option  v-for="item in tableData" :key="item.company_id" :label="item.company_name" :value="item.company_name" ></el-option>
          </el-select>
           <el-input v-else placeholder="巡查对象" disabled v-model="form.company_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
     </el-row>
        </el-form>
        </el-main>
  </el-container>
  <el-container v-else-if="form.patrol_type==1"  class="patroladd">
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
       <el-breadcrumb-item  to="/admin/patrol">巡查列表</el-breadcrumb-item>
       <el-breadcrumb-item>新增巡查</el-breadcrumb-item>
    </el-breadcrumb>
        <div> <el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="p15">
        <el-form :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
     <div class="box mt20 f14">
        <div class="tl c333" >工单号：{{form.work_order}}</div>
     </div>
     <el-collapse class="box mt20 f14" v-model="activeNames" @change="handleChange">
       <el-collapse-item title="巡查信息" name="1">
        <el-row class="box mt20  f14 c666">
           <el-col :span="11 "  class="tl">
          <el-form-item class="w " label-width="28%"   label="巡查人：" size="medium" prop="patrol_user_name" :rules="[{required: true,message: '请选巡查人', trigger: ['blur','change']}]">
          <el-select v-model="form.patrol_user_name" filterable placeholder="请选择巡查人"  clearable @change="change(5,form.patrol_user_name)">
            <el-option  v-for="item in user_list" :key="item.user_name" :label="item.user_name" :value="item.user_name" ></el-option>
          </el-select>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
          <el-form-item class="w" label-width="28%" label="巡查时间：" size="medium" prop="patrol_time" :rules="[{required: true,message: '请选择日期', trigger: 'blur' }]">
           <el-date-picker type="date" placeholder="选择日期" :picker-options="pickerOptionsStart" v-model="form.patrol_time"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;" @change="change(3,form.patrol_time)"></el-date-picker>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
        <el-form-item class="w" label-width="28%" label="巡查对象：" size="medium" >
           <el-input placeholder="巡查对象" disabled v-model="form.company_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl">
         <el-form-item class="w" label-width="28%" label="巡查结果：" size="medium" prop="patrol_result" :rules="[{required: true,message: '请选择巡查结果', trigger: ['blur','change']}]">
           <el-select v-model="form.patrol_result" clearable placeholder="请选择巡查结果" @change="change(6,form.patrol_result)">
             <el-option v-for="group in options4" :key="group.title" :label="group.title" :value="group.title"> </el-option>
          </el-select>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
        <el-form-item class="w" label-width="28%" label="备注信息：" size="medium" >
           <el-input placeholder="请输入备注信息"  v-model="form.note" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
     </el-row>
       </el-collapse-item>
       <el-collapse-item v-if="form.patrol_result=='不合格'" title="处罚信息" name="2">
         <el-row  class="box mt20  f14 c666">
        <el-col :span="11" class="tl" >
          <el-form-item class="w" label-width="28%" label="处理方式：" size="medium" >
          <el-select v-model="form.dispose_name" clearable placeholder="请选择处理方式" @change="change(1,form.dispose_name)">
             <el-option v-for="group in options" :key="group.dispose_id" :label="group.dispose_name" :value="group.dispose_id"> </el-option>
          </el-select>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl" >
          <el-form-item class="w" label-width="28%" label="处罚方式：" size="medium" >
          <el-select  v-model="form.punish_name" placeholder="请选择处罚方式"  @change="change(2,form.punish_name)">
             <el-option  v-for="group in options1" :key="group.punish_id" :label="group.punish_name" :value="group.punish_id"> </el-option>
          </el-select>
        </el-form-item>
        </el-col>
     </el-row>
       </el-collapse-item>
       <el-collapse-item title="巡查图片" name="3">
           <el-col :span="24" class="tl">
              <div class="recordadd-upload mt15">
              <el-upload 
                ref="upload"
                multiple
                v-if="imageUrl=='1234567'"
                action="/pc/file/upload"
                 accept="image/png,image/jpg,image/jpeg"
                list-type="picture-card"
                :on-success="handlepoll"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-upload 
                ref="upload"
                multiple
                action=""
                v-else
                 accept="image/png,image/jpg,image/jpeg"
                list-type="picture-card"
                :http-request="(params) =>{return handlepoll(params)}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
              </div>
      </el-col>
       </el-collapse-item>
      </el-collapse>
        <div class="tl mt15">
             <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
           </div>
        </el-form>
        </el-main>
  </el-container>
 <el-container v-else-if="form.patrol_type==2" class="patroladd">
    <el-header class="box add bb">
        <h5 class="mb10"><span class="lines"></span>双随机工单派发</h5>
        <div>
             <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
            <el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="p15 pt0">
        <el-form :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
     <div class="box mt20 f14">
        <div class="tl c333" >工单号：{{form.work_order}}<span class="ml10 f13 patrol_way">双随机工单派发</span></div>
     </div>
     <el-row class="box mt20  f14">
         <el-col :span="24" class="patroladd-title bgcfbe tl mb20 pl15">工单信息</el-col>
         
         <el-col :span="11" class="tl">
        <el-form-item class="w" label-width="28%" label="派发人：" size="medium" >
           <el-input placeholder="派发人" disabled v-model="user_name" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="11" class="tl">
          <el-form-item class="w" label-width="28%" label="巡查时间：" size="medium" prop="patrol_time" :rules="[{required: true,message: '请选择日期', trigger: 'blur' }]">
           <el-date-picker type="date" placeholder="选择日期" :picker-options="pickerOptionsStart" v-model="form.patrol_time"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;" @change="change(3,form.patrol_time)"></el-date-picker>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
         <el-form-item class="w" label-width="28%" label="巡查人：" size="medium" prop="patrol_user_name" :rules="[{required: true,message: '请选择巡查人', trigger: ['blur','change']}]">
            <el-input placeholder="巡查人" disabled v-model="form.patrol_user_name" class="input-with-select">
               <el-button slot="append" @click="startLottery1">随机抽查<i class="iconfont iconshuaxin general_color f14"></i></el-button>
            </el-input>
        </el-form-item>
         <ul class="user_list tr">
        <li v-for="(item,i) in user_list"  :key="i">
          <div class="box">
            <el-button size="medium" :type="i==index?'primary':''">{{item.user_name}}</el-button>
          </div>
        </li>
      </ul>
          
        </el-col>
        <el-col :span="11" class="tl" >
        <el-form-item class="w" label-width="28%" label="巡查对象：" size="medium"  prop="company_name" :rules="[{required: true,message: '请输入巡查对象', trigger: 'blur' }]">
           <el-input placeholder="巡查对象" disabled v-model="form.company_name" class="input-with-select">
             <el-button slot="append" @click="startLottery2">随机抽查<i class="iconfont iconshuaxin general_color f14"></i></el-button>
            </el-input>
        </el-form-item>
        <ul class="user_list  tr">
        <li v-for="(item,i) in tableData" :key="i">
          <div class="box">
            <el-button size="medium" :type="i==index2?'primary':''" :title="item.company_name">{{item.company_name}}</el-button>
          </div>
        </li>
      </ul>
        </el-col>
     </el-row>
        </el-form>
        </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session } from '../../../util/util'
import { tengxuncloud } from '@/util/aliOss'
function findSystemCode(){
var year = new Date().getFullYear();
var month = new Date().getMonth() + 1;
var day = new Date().getDate();
 var num = '';
if (month < 10) {month = "0" + month; }
if (day < 10) {day = "0" + day;}
for(var i=0;i<5;i++){num+=Math.floor(Math.random()*10);}
     return year + month+ day+num
}
export default {
  data () {
    return {
      index: -1,  // 当前转动到哪个位置，起点位置
      count:'',  // 总共有多少个位置
      timer: 0,  // 每次转动定时器
      speed: 200,  // 初始转动速度
      times: 0,    // 转动次数
      cycle: 50,   // 转动基本次数：即至少需要转动多少次再进入抽奖环节
      prize: -1,   // 中奖位置
      click: true,
      index2: -1,  
      count2:'', 
      timer2: 0, 
      speed2: 200, 
      times2: 0, 
      cycle2: 50, 
      prize2: -1, 
      click2: true,
      company_name:'',
      user_name:'',
      region_code:'',
      user_list:'',
      tableData:'',
        form: {
          patrol_type:'',
          work_order:'',
        patrol_user_id:'',
        patrol_user_name: '',
        patrol_result:'',
        patrol_time:'',
        dispose_id:'',
        dispose_name:'',
        punish_name:'',
        punish_id:'',
        note:'',
        company_id:'',
        company_name:'',
        accessory_list:[]
      },
      options:'',
      options1:'', 
      options7:'',
      activeNames: ['1'],
        options4:[{title: '合格',name: '1'}, {title: '不合格',name: '2'}],
              patrolOptions: [
        { label: "巡查工单", value: "1" },
        { label: "双随机工单", value: "2" },
        { label: "派发工单", value: "3" }
      ],
       dialogImageUrl: '',
        dialogVisible: false,
        aa:'',
        aa2:'',
         pickerOptionsStart: {
        disabledDate: time => {
            return time.getTime() < Date.now() - 8.64e7;
        }
      },
    }
  },
     created () {
       this.type=this.$route.query.type;
          if(this.type==1){
            this.form.patrol_type =session.get('params').id?String(session.get('params').id):'';
        this.form.company_id=session.get('params').id1?String(session.get('params').id1):'';
        this.id2=session.get('params').id2?String(session.get('params').id2):'';
         this.form.company_name=session.get('params').name;
          }else{
            this.form.patrol_type =session.get('params1').id?String(session.get('params1').id):'';
        this.form.company_id=session.get('params1').id1?String(session.get('params1').id1):'';
        this.id2=session.get('params1').id2?String(session.get('params1').id2):'';
         this.form.company_name=session.get('params1').name;
          }
        
        this.user_name=local.get('user').user_name;
        this.form.work_order=findSystemCode()
      this.init()
  },
  methods: {
    init (params) {
      axios.get('/pc/patrol-dispose/all', '').then((v) => {
       this.options=v.data.patrol_dispose_list
      })
        axios.get('/pc/patrol-punish/all', '').then((v) => {
       this.options1=v.data.patrol_punish_list
      })
      if(this.form.patrol_type==3){
        if(this.id2==1){
             this.init1()
             this.init2()
        }else{
             this.init1()
        }
        
      }else if(this.form.patrol_type==2){
         this.init1()
       this.init2()
      }else{
        this.init1()
      }
    },
    init1(params){
     axios.get('/pc/user/list', {pager_openset:'1000',user_type:'2',query_param:'all_manage_user',user_state:'1',user_province:local.get('user').province==null?'':local.get('user').province,user_city:local.get('user').city==null?'':local.get('user').city,user_area:local.get('user').area==null?'':local.get('user').area,user_town:local.get('user').town==null?'':local.get('user').town,user_vill:local.get('user').vill==null?'':local.get('user').vill,}).then((v) => {
            this.user_list=v.data.user_list;
            this.count=v.data.pager_count
      })
    },
    init2(){
       axios.get('/pc/company/list', {pager_openset:'1000'}).then((v) => {
        this.tableData=v.data.company_list;
        this.count2-v.data.pager_count
      })
    },
    handleChange(){},
     change(index,val){
       if(index==1){
         this.form.dispose_id=val;
         var obj = {}
      obj = this.options.find(function(item){
        return item.dispose_id === val
      })
       this.form.dispose_name=obj.dispose_name}
        if(index==2){ this.form.punish_id=val;
         var obj = {}
      obj = this.options1.find(function(item){
        return item.punish_id === val
      })
       this.form.punish_name=obj.punish_name
       }
       if(index==3){this.form.patrol_time=val;}
       if(index==4){
        this.tableData.forEach(item=>{
            if(item.company_name==val){
              this.form.company_id=item.company_id
            }
          })
       }
       if(index==5){this.form.patrol_user_name=val;
          this.user_list.forEach(item=>{
            if(item.user_name==val){
              this.form.patrol_user_id=item.user_id
            }
          })
       }
       if(index==6){this.form.patrol_result=val;
       }
     },
     submitForm(form){
          let paramas=this.form;
         this.$refs[form].validate((valid) => {
        if (valid) {
          axios.post('/pc/patrol/save', paramas).then((v) => {
            this.$router.back()
         })
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
         handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
          handlepoll(response, file, fileList) {
            if(this.imageUrl=='1234567'){
           this.form.accessory_list.push({accessory_type:'巡查',accessory_url:response.data.visit_url});
          }else{
           tengxuncloud(response).then((res) => {
             this.form.accessory_list.push({accessory_type:'巡查',accessory_url:res.url});
           })
          }
             
    },
       handleRemove(file, fileList) {
         this.form.accessory_list=[];
         fileList.forEach(item1 => {
            this.form.accessory_list.push({accessory_type:'巡查',accessory_url:item1.response.data.visit_url});
        });
      },
       startLottery1(){
      if (!this.click) { return }
       this.startRoll1();
     },
      startLottery2(){
      if (!this.click2) { return }
       this.startRoll2();
     },
     startRoll1 () {
			this.times += 1 
			this.oneRoll() 
			if (this.times > this.cycle + 10 && this.prize === this.index) {
        clearTimeout(this.timer) 
         this.form.patrol_user_name=this.aa;
         this.user_list.forEach(item=>{
            if(item.user_name==this.aa){
              this.form.patrol_user_id=item.user_id
            }
          })
			  this.prize = -1
			  this.times = 0
			  this.speed = 200
			  this.click = true; 
			  var that = this;	                  
			} else {
			  if (this.times < this.cycle) {
        this.speed -= 10  
        if(this.speed<=80){  this.speed =80 }
			  } else if (this.times === this.cycle) { 
				const index = parseInt(Math.random() * this.count) || 0;
          this.prize = index;
         this.aa=this.user_list[index].user_name
				if (this.prize > this.count) { this.prize = this.count }
			  } else if (this.times > this.cycle + 10 && ((this.prize === 0 && this.index === (this.count-1)) || this.prize === this.index + 1)) {
				this.speed += 50
			  } else {
				this.speed += 20
        }    
			  if (this.speed < 40) {this.speed = 40}
			  this.timer = setTimeout(this.startRoll1, this.speed)
			}
		},
 startRoll2 () {
			this.times2 += 1 
			this.oneRoll2() 
			if (this.times2 > this.cycle2 + 10 && this.prize2 === this.index2) {
        clearTimeout(this.timer2) 
       
         this.form.company_name=this.aa2.company_name;
         this.form.company_id=this.aa2.company_id;
			  this.prize2 = -1
			  this.times2 = 0
			  this.speed2= 200
			  this.click2 = true; 
			  var that = this;	                  
			} else {
			  if (this.times2 < this.cycle2) {
        this.speed2 -= 10  
        if(this.speed2<=80){  this.speed2 =80 }
			  } else if (this.times2=== this.cycle2) { 
				const index2 = parseInt(Math.random() * this.count2) || 0;
          this.prize2 = index2;
        this.aa2=this.tableData[index2];
				if (this.prize2 > this.count2) { this.prize2 = this.count2 }
			  } else if (this.times2 > this.cycle2 + 10 && ((this.prize2 === 0 && this.index2 === (this.count2-1)) || this.prize2 === this.index2 + 1)) {
				this.speed2 += 50
			  } else {
				this.speed2 += 20
        }    
			  if (this.speed2 < 40) {this.speed2 = 40}
			  this.timer2 = setTimeout(this.startRoll2, this.speed2)
			}
		},
		// 每一次转动
		oneRoll () {
		  let index = this.index // 当前转动到哪个位置
      const count = this.count // 总共有多少个位置
		  index += 1
		  if (index > count - 1) { index = 0 }
      this.index = index
    },
    	oneRoll2 () {
		  let index2 = this.index2 // 当前转动到哪个位置
      const count2 = this.count2 // 总共有多少个位置
		  index2 += 1
		  if (index2 > count2 - 1) { index2 = 0 }
      this.index2 = index2
		},
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  /deep/ .el-input-group__append, .el-input-group__prepend{background-color:#fff;color: #2EC68A}
.patrol_way{padding: 5px;background-color:rgba($color: #2EC68A, $alpha: 0.2);color:#2EC68A; border-radius: 4px;}
.user_list li{padding: 3px;display: inline-block;}
.user_list .box .el-button--medium{width: 5.2rem;
	overflow: hidden;     /*设置超出的部分进行影藏*/
	text-overflow: ellipsis;     /*设置超出部分使用省略号*/
	white-space:nowrap ;}

}

</style>
